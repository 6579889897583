import { Form, Row, Col, Button, FormGroup } from "react-bootstrap";
import InputMask from 'react-input-mask'

const CreditForm = ({setFirstName, setLastName, setCardNumber, submit, setEmail, setExpirationMonth, setExpirationYear, setAutopay, setCvv}) => {
    return(
        <div>
            <Row className="mb-6">
                <Form.Group as={Col}>
                <Form.Label>First Name:* </Form.Label>
                <Form.Control onChange={(e) => setFirstName(e.target.value)}/>
                </Form.Group>
                <Form.Group as={Col}>
                <Form.Label>Last Name:* </Form.Label>
                <Form.Control onChange={e => setLastName(e.target.value)}/>
                </Form.Group>
            </Row>
            <Row>
                <Form.Group as={Col}>
                    <Form.Label>Card Number:* </Form.Label>
                    <FormGroup>
                        <InputMask 
                            style={{
                                width:'48%',
                                borderStyle: 'solid',
                                borderWidth: '1px',
                                borderColor: "lightgray",
                                borderRadius: '3px',
                                height: '40px',
                                outlineColor: '#6ab5de',
                                outlineWidth: '3px',
                            }} 
                            mask='9999 9999 9999 9999' 
                            onChange={(e)=>setCardNumber(e.target.value)}/>
                    </FormGroup>
                </Form.Group>
            </Row>
            <Row className="mb-6">
            <Form.Group as={Col} >
                    <Form.Label>Expiration Date: </Form.Label>
                    <div  style={{display: 'flex'}}>
                    <select className='form-control' onChange={(e) => setExpirationMonth(e.target.value)}>
                        <option value="1">01</option>
                        <option value="2">02</option>
                        <option value="3">03</option>
                        <option value="4">04</option>
                        <option value="5">05</option>
                        <option value="6">06</option>
                        <option value="7">07</option>
                        <option value="8">08</option>
                        <option value="9">09</option>
                        <option value="10">10</option>
                        <option value="11">11</option>
                        <option value="12">12</option>
                    </select> 
                    <select className='form-control' onChange={(e)=>setExpirationYear(e.target.value)}>
                        <option value="2024">2024</option>
                        <option value="2025">2025</option>
                        <option value="2026">2026</option>
                        <option value="2027">2027</option>
                        <option value="2028">2028</option>
                        <option value="2029">2029</option>
                        <option value="2030">2030</option>
                        <option value="2031">2031</option>
                        <option value="2032">2032</option>
                    </select>
                    </div>
                </Form.Group>
                <Form.Group>
                    <Form.Label>CVV:*</Form.Label>
                    <Form.Control onChange={e => setCvv(e)}/>
                </Form.Group>
                <Form.Group as={Col}>
                    <div className='center-check-box'>
                    <Form.Check
                        label='Sign Up For Autopay' 
                        type="checkbox"
                        onChange={(e) => setAutopay(e.target.value)}
                        />
                    </div>
                </Form.Group>
            </Row>
            <Row>
                <Form.Group as={Col}>
                    <Form.Label>Email Address: </Form.Label>
                    <Form.Control type='email' onChange={(e) => setEmail(e.target.value)}/>
                </Form.Group>
            </Row>
            <Row>
                <Col>
                    <Button variant='outline-success' type="button" onClick={() => submit()}>Send Payment</Button>
                </Col>
            </Row>
        </div>
    )
}

export default CreditForm;