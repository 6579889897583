import { useState } from "react";
import { Nav, Navbar, Form, Button, NavDropdown } from "react-bootstrap"
import ContactModal from "./contactmodal";
import logo from "../images/CustomLawnsnewsmalllogo.png"

const Header = () => {
    const [modalOpen, setModalOpen] = useState(false);

    const estimateClicked = () => {
        setModalOpen(true);
    }

    return(
        <header>
            <Navbar style={{marginTop: '-30px'}} expand="lg">
  <Navbar.Brand href="/" style={{fontSize:'20px'}}>
       <img
       alt=""
       src={logo}
       className='clo-header-image-style'
       />{' '}
       Custom Lawns Of Omaha 
   </Navbar.Brand>
  <Navbar.Toggle aria-controls="basic-navbar-nav" />
  <Navbar.Collapse id="basic-navbar-nav">
    <Nav className="mr-auto">
      <NavDropdown title="Services" id="basic-nav-dropdown">
        <NavDropdown.Item href="/#/services/organicfertilizer">Organic Fertilizer</NavDropdown.Item>
        <NavDropdown.Item href="/#/services/aeration">Aeration</NavDropdown.Item>
        <NavDropdown.Item href="/#/services/pestcontrol">Pest Control</NavDropdown.Item>
      </NavDropdown>
      <Nav.Link href='/#/faq'>Lawncare Tips</Nav.Link>
      <Nav.Link href='/#/invoice'>Pay Your Bill</Nav.Link>
      <Form inline>
          <Button variant='outline-success' onClick={estimateClicked}>Get An Estimate</Button>
      </Form>
    </Nav>
  </Navbar.Collapse>
</Navbar>
<ContactModal open={modalOpen} toggle={setModalOpen}/>
        </header>
    )
}

export default Header;