import { Col, Row } from "react-bootstrap";
import Layout from "../components/layout";

const Aeration = () =>{
    return(
        <Layout>
            <h4>About Aeration</h4>
            <Row>
                <Col>
                <h6>What is Aeration</h6>
                Aeration is the removal of small cores of soil to allow air, moisture and fertilizer down to the root zone. Aeration helps your lawn to breathe easier. The removal of these small cores in a lawn also helps prevent water run-off by allowing moisture to reach the root zone of your grass.
                <br></br><br></br>
                </Col>
            </Row>
            <Row>
                <Col>
                <h6>How Is Aeration Done?</h6>
                Our specially designed aeration equipment consists of a series of hollow coring tines mounted on wheels. When these hollow tines are rolled over your lawn, they puncture the soil systematically removing small soil "plugs". The equipment is compact and can be easily used in confined areas.
                <br></br><br></br>
                </Col>
            </Row>
            <Row>
                <Col>
                <h6>How Does Aeration Help Your Lawn?</h6>
                The core removal helps reduce soil compaction, which can be a problem in both newer and older lawns. Aeration also helps retard thatch build-up by increasing micro-organism activity, allowing thatch to decompose naturally. Aeration also saves watering because run-off is reduced allowing moisture to get into the soil faster and deeper.
                <br></br><br></br>
                </Col>
            </Row>
            <Row>
                <Col>
                <h6>Professional Lawn Aeration</h6>
                Professional lawn aeration is an important part of good lawn care. It can help you achieve a beautiful, attractive lawn that is the show place of the neighborhood. Aeration can really benefit you as well as your lawn. Aeration can help you save money on your watering bill, by allowing the moisture to reach the root zone faster and more easily. Soil compaction barriers can best be eliminated through aeration which in turn creates the proper soil, air and water relationship.
                <br></br><br></br>
                </Col>
            </Row>
        </Layout>
    )
}

export default Aeration;