import Footer from "./footer";
import Header from "./header";
import { Container } from "react-bootstrap";

const Layout = ({children}) => {
    return(
        <div style={{backgroundColor: '#e6f6e6'}}>
        <Container>
            <Header></Header>
            {children}
        </Container>
        <Footer></Footer>
        </div>
    )
}

export default Layout;