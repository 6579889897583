import { Carousel } from "react-bootstrap"
import organic from '../images/coolgardenorganic.jpg'
import local from '../images/lawntreelocal.jpg'
import modern from '../images/nicehousetitled.jpg'

const ImageCarousel = () =>{
    return(
        <Carousel>
  <Carousel.Item>
    <img
      className="d-block w-100"
      src={organic}
      alt="Organic"
    />
    <Carousel.Caption>
      <h4> We use organic based fertilizer, which is safer
          and primes your lawn for long term growth. </h4>
    </Carousel.Caption>
  </Carousel.Item>
  <Carousel.Item>
    <img
      className="d-block w-100"
      src={local}
      alt="Local"
    />

    <Carousel.Caption>
      <h4>We are locally owned and have been operating in the Omaha area since 1980.</h4>
    </Carousel.Caption>
  </Carousel.Item>
  <Carousel.Item>
    <img
      className="d-block w-100"
      src={modern}
      alt="Modern"
    />

    <Carousel.Caption>
      <h3>We are a modern company, saving you time and headaches.</h3>
    </Carousel.Caption>
  </Carousel.Item>
</Carousel>
    )
}

export default ImageCarousel;