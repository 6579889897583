import { Col, Row } from "react-bootstrap";
import Layout from "../components/layout";

const Privacy = () =>{
    return(
        <Layout>
            <Row>
                <Col>
                    <h3>Our Privacy Practices</h3>
                     <h6>General Usage</h6>
                     <p>This site does not keep track of any usage and does not use cookies for general usage.</p>
                     <h6>Accounts and Payments</h6>
                     <p>When logging in to pay your bill and view invoice, the site will use cookies to track login information.
                         When paying online we capture only information needed to complete payment through our third party payment providers.
                         For information gathered by our third party payment providers can be found at Authorize.net and paypal.com.
                     </p>
                </Col>
            </Row>
        </Layout>
    );
}

export default Privacy;