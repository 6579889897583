import React from "react";
import ReactDOM from 'react-dom';
import {
  HashRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Privacy from './pages/privacy';
import Services from './pages/services';
import FAQ from './pages/faq';
import Invoice from './pages/invoice';
import SiteMap from "./pages/sitemap";
import Payments from "./pages/payment";

ReactDOM.render(
  <React.StrictMode>
    <Router>
    <Switch>
      <Route exact path='/' component={App}/>
      <Route path='/services' component={Services} />
      <Route path='/faq' component={FAQ} />
      <Route path='/invoice' component={Invoice} />
      <Route path='/privacy' component={Privacy} />
      <Route path='/sitemap' component={SiteMap} />
      <Route path='/payments' component={Payments} />
    </Switch>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
