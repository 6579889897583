import { Col, Row } from "react-bootstrap";
import Layout from "../components/layout";

const SiteMap = () =>{
    return(
        <Layout>
            <Row>
                <Col>
                    <a href="/">Custom Lawns Of Omaha</a><br></br>
                    <a href="/#/services">Services</a><br></br>
                    <a href="/#/services/organicfertilizer">Organic Fertilizer</a><br></br>
                    <a href="/#/services/aeration">Aeration</a><br></br>
                    <a href="/#/services/pestcontrol">Pest Control</a><br></br>
                    <a href="/#/faq">FAQ</a><br></br>
                    <a href="/#/invoice">Invoice</a><br></br>
                    <a href="/#/privacy">Privacy</a><br></br>
                    </Col>
            </Row>
        </Layout>
    );
}

export default SiteMap;