import { Row, Col } from "react-bootstrap";
import Layout from "../components/layout";
import HolganixLogo from '../images/Holg.PNG'

const OrganicFertilizer = () =>{
    return(
        <Layout>
            <h4>About Our Organic Fertilizer</h4>
            <Row>
                <Col md={7}>            
  Our organic fertilizer program is a bio-nutrional fertilizer designed
  to maximize your lawns potential. The word "bio-nutrional" means it
  works with a probiotic approach, promoting more efficient use of the 
  soil's surrounding nutrients while utilizing organic food-grade 
  edible ingredients. It contributes to a more robust eco system in 
  and around your soil and plants, adding to your plants' overall 
  health. In short, bio-nutritionals promote healthier plants, and 
  healthier plants make for a healthier ecosystem. With Holganix, you 
  will recieve fewer weeds naturally, fewer insects and less disease 
  naturally, natural pH adjustment, improved root development and 
  better drought resistance.
  </Col>
  <Col><img src={HolganixLogo} /> </Col>
  </Row>
  <Row>
      <Col>
      <br></br> <br></br>
   The natural ingredients found in Holganix are plant based proteins,
    compost tea plus many other bio-nutrional products(like 
    mycorrhizae and humates). The compost tea contain essential 
    natural amino acids, chelated secondary elements, vitamins and 
    enzymes. The all natural ingredients in Holganix supply the food 
    energy that increases the number of microbes found in the soil as
     well as provide the basic nutrients needed for healthy plant 
     growth. Due to the special combination of fertilizer, amino acids,
    mycorrhizae, humates and probiatics we have seen a reduction of
    weeds, thicker turf and a substantial reduction of turf disease.
    The amino acids and mycorrhizaes in the fertilizer help to 
    deliver the fertilizer to the roots system faster, which in 
    turn makes the root system stronger, healthier and thicker.
        <br></br><br></br>
        </Col>
</Row>
<Row>
    <Col>
        <h6>A Complete List of the ingredients in our fertilizier</h6>
        <ul>
            <li><p class='green-title'>Compost Tea Extract:</p>
            <p>Contains beneficial, indigeous mirco-orgainisms -bacteria, fungi, protozoa and nematodes, improves soil quality and suppress plant disease.
                </p> </li>
            <li><p class='green-title'>Humic and Fulvic Acids:</p><p>Supports respiration of micro-organisms in the soil, enhances seed germination and promotes root development and absorption of micronutrients.
                </p> </li>
            <li><p class='green-title'>Kelp:</p><p>Stimulates uptake of pant nutrients, promotes seed gemination and plant cell division.</p> </li>
            <li><p class='green-title'>Gluten:</p><p>A nitrogen source that also has pre-emergent herbicide properties.</p> </li>
            <li><p class='green-title'>Endomycorrhizae:</p><p>Penetrates plant roots and assist with water and nutrient uptake.</p> </li>
            <li><p class='green-title'>Ectomycorrhizae:</p><p>Grow in association with plant roots and assist with water and nutrient uptake.</p> </li>
            <li><p class='green-title'>Nitrogen Fixing Bacteria:</p><p>Aides in the nitrification process making nitrogen available from the air and soil.
                </p> </li>
            <li><p class='green-title'>Normal Flora:</p><p>Bacteria normally found in the soil that help suppress disease pathogens.</p> </li>
            <li><p class='green-title'>Protozoa:</p><p>Eats bacteria and release nutrients to the soil.</p> </li>
            <li><p class='green-title'>Beneficial Nematodes:</p><p>Multi-cell organisms which eat bacteria, fungi, other nematodes and insects protectng plants from injury and disease and releasing nutrients to the soil.
                </p> </li>
            <li><p class='green-title'>Tea Tree Oil:</p><p>Essential oil with anti-fungal and insect suppression properties; extracted from tea tree leaves.
                </p> </li>
            <li><p class='green-title'>Saccharomyces cerevisiae:</p><p>A source of simple carbohydrates that aid in rooting and serve as a food source for micro-organisms in the soil.
                </p> </li>
            <li><p class='green-title'>Polysaccharide:</p><p>A food source for micro-organisms in the soil.</p> </li>
            <li><p class='green-title'>Yucca Extract:</p><p>A natural spreader sticker, wetting agent and soil conditioner.</p> </li>
            <li><p class='green-title'>Milky Spore Developer:</p><p>Bacterium spores for suppression of Japanese beetle grubs.</p> </li>
            <li><p class='green-title'>Amino Acid:</p><p>Building block of proteins and a nitrogen source for plants, essential for cell division and growth.</p> </li>
            <li><p class='green-title'>Chitinase:</p><p>An enzyme that stimulates the destruction of fungi and insect exoskeleton.</p> </li>
            <li><p class='green-title'>Auxin:</p><p>A plant growth hormone positively affecting cellular growth and differentiation.
                </p> </li>
            <li><p class='green-title'>Streptomyces:</p><p>Bacteria that suppess against soil born plant diseases: Phytopthora, Pythium, Fusarium and Dollar Spot.
                </p> </li>
            <li><p class='green-title'>Trichoderma:</p><p>Fungi that suppress root diseases: Phytopthora, Pythium, Fusarium and Dollar Spot.
                </p> </li>
            <li><p class='green-title'>Lignin Polymers:</p><p>Lignin polymers present in Holganix were part of cell walls of plants. Resistant to further break down, these polymers help to create air channels in soil and assist in the aggregation of organic matter in the soil.
                </p> </li>
            <li><p class='green-title'>Secondary and Micronutrients:</p><p>calcium, copper, zinc, iron, molybdenum, boron and magnesium.</p> </li>
        </ul>
    </Col>
</Row>
<Row>
    <Col>
Many other organic fertilizers boast being organic, however there are 
not as beneficial to your lawn as the Holganix product. Healthy Grow 
is coated with Holganix's bio-nutritional fertilizer. This is 
essentially two fertilizers in one. Some organic fertilizer is 
milorginite and this is essentially, dried sewage waste. Holganix 
fertilizer is a non-poisonous, food grade product. It is safe for 
water in your community, is non-flammable, and nearly eliminates the 
need for nitrates, phosphates and pesticide products. These 
bio-nutrients supply the food energy of feed and stimulates the 
natural soil microbes which aid in the breakdown of the product. Sewer
 sludge fertilizers are formulated with human and industrial waste 
 by-products and are high in denatured proteins. Denatured proteins 
 offer some initial color response from the iron found in this product, 
 but mainly add organic biomass to the soil. Composted based 
 fertilizers are formulated from animal manures and their bedding 
 materials. Composting uses the natural aerobic and anaerobic microbes 
 as well as the free ammonia found in manure to breakdown the complex 
 cellulose fiber. The finished product is then sterilized by heat to 
 destroy any harmful pathogens.
 <br></br><br></br>
</Col>
</Row>
<Row>
    <Col>
    <h6>Learn more about Holganix <a href="https://www.holganix.com/" style={{color:'gray'}}>here</a></h6>
</Col>
</Row>      
        </Layout>
    );
}

export default OrganicFertilizer