import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import axios from "axios";

const Paypal = ({cid, payAmount}) => {
    var customerid = Number(cid);
   // alert(customerid);
    var finalval = Number(payAmount);
   // alert(finalval);

    return(
        <PayPalScriptProvider options={{ 
                "client-id": "Abhl383AiXuHk5OrBnnc_tFJ_qk8b8nS76HTJA4DecUM1GD-WDLUeDli-VOAVjOSmoZZB2vhKUQuXWcv", 
                "enable-funding": "venmo",  
            }}>
            <PayPalButtons
                createOrder={(data, actions) => {
                    return actions.order.create({
                        purchase_units: [{
                            amount: {
                            value: finalval
                            },
                            custom_id: customerid,
                        }],
                    });
                }}
                onError={(err) =>{
                    alert('Something happened - Please try again');
                }}
                onApprove={(data, actions) => {
                        return actions.order.capture().then(function(details) {
                            axios.post('update_application_paypal.php', {
                                    orderID: data.orderID,
                                    amount: details.purchase_units[0].amount.value,
                                    epaymethod: "PayPal",
                                    customerid: details.purchase_units[0].custom_id,
                                }).then(function (response){
                                    alert('Payment succesfully submitted. If your screen does not refresh, please return to customlawnsofomaha.com');
                                    window.location.href = "https://www.customlawnsofomaha.com"
                                });
                        });
                    }}
                fundingSource={undefined}
            />
    </PayPalScriptProvider>
    )
}

export default Paypal;