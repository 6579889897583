import { useState } from "react";
import { useHistory } from "react-router-dom";
import { Form, Row, Col } from "react-bootstrap"
import Paypal from "./paypal";
import CheckForm from "./checkform";
import CreditForm from "./creditform";
import axios from 'axios';


const PaymentMasterForm = ({ price, customerid, acctNumber, invCompId }) => {
    const [paymentAmount, setPaymentAmount] = useState(price);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [routingNumber, setRoutingNumber] = useState('');
    const [checkingNumber, setCheckingNumber] = useState('');
    const [expirationMonth, setExpirationMonth] = useState('1');
    const [expirationYear, setExpirationYear] = useState(new Date().getFullYear().toString());
    const [email, setEmail] = useState('');
    const [autopay, setAutopay] = useState(false);
    const [paymentType, setPaymentType] = useState('full');
    const [paymentMethod, setPaymentMethod] = useState('');
    const [cardNumber, setCardNumber] = useState('');
    const [cvv, setCvv] = useState(undefined);
    const [showError, setShowError] = useState(false);
    const [requiredFields, setRequiredFields] = useState(false)
    let history = useHistory();

    const submit = () => {
        if (paymentMethod === 'credit') {
            if (firstName
                && lastName
                && cardNumber 
                && cvv 
                && cardNumber.replaceAll(' ', '').length === 16) {
                    setRequiredFields(false);
                    submitCreditCardPayment();
            }
            else {
                setRequiredFields(true);
            }
        }
        else if (paymentMethod === 'echeck') {
            if (firstName
                && lastName
                && routingNumber
                && checkingNumber) {
                    setRequiredFields(false);
                    submitCheckPayment();
            }
            else {
                setRequiredFields(true);
            }
        }
    }

    const paymentMethodSelection = (e) => {
        setPaymentMethod(e.target.value);
    }

    const updatePaymentType = (e) => {
        setPaymentType(e.target.value);
    }

    const setCvvOnForm = (e) => setCvv(e.target.value);

    const submitCheckPayment = () => {
        axios.post('/api/processcheckpayment.php', {
                inv_comp_id: invCompId,
                cust_id: customerid,
                acct_num: acctNumber,
                email: email,
                fname_chk: firstName,
                lname_chk: lastName,
                bank_aba_number: routingNumber,
                bank_acct_number: checkingNumber,
                amount: paymentAmount,
        })
            .then(function (response) {
                if (response.status === 200) {
                    alert('Your payment was succesful, redirecting to the homepage');
                    history.push('/');
                }
                else {
                    setShowError(true);
                }
            })
            .catch(function (error) {
                setShowError(true);
            });
    }

    
    const submitCreditCardPayment = () => {
        axios.post('/api/processcreditcardpayment.php', {
                inv_comp_id: invCompId,
                cust_id: customerid,
                acct_num: acctNumber,
                email: email,
                fname: firstName,
                lname: lastName,
                cc_number: cardNumber.replaceAll(' ', ''),
                expmonth: expirationMonth,
                expyear: expirationYear,
                amount: paymentAmount,
                autopay: autopay,
                cvv: cvv,
        }).then(function (response) {
            if (response.status === 200) {
                console.log(response)
                alert('Your payment was succesful, redirecting to the homepage');
                history.push('/');
            }
            else {
                setShowError(true);
            }
        })
            .catch(function (error) {
                setShowError(true);
            });
    }

    return (
        <div>
            {showError ?
                <div> Payment was unsuccessful, please contact
                    customlawnsomaha@gmail.com or try again later. </div> :
                <Form>
                    <Row>
                        <Form.Group as={Col}>
                            <Form.Check type={'radio'}
                                label={`Pay Full Account Balance: $` + price}
                                name='payment'
                                value='full'
                                checked={paymentType === 'full'}
                                onChange={updatePaymentType}
                            />
                        </Form.Group>
                    </Row>
                    <Row>
                        <Form.Group as={Col}>
                            <Form.Check type="radio"
                                name='payment'
                                value='partial'
                                label='Pay partial Account Balance: '
                                checked={paymentType === 'partial'}
                                onChange={updatePaymentType}
                            />

                            <Form.Control disabled={paymentType === 'full'} name='partialPayment' placeholder='Partial Amount' onChange={(e) => setPaymentAmount(e.target.value)} />
                        </Form.Group>
                    </Row>
                    <Row>
                        <Form.Group as={Col}>
                            <select className='form-control' style={{ maxWidth: '300px' }} onChange={paymentMethodSelection}>
                                <option>Select Payment Method</option>
                                <option value='credit'>Credit Card</option>
                                <option value='paypal'>Paypal</option>
                                <option value='echeck'>Checking Account</option>
                            </select>
                        </Form.Group>
                    </Row>
                    {requiredFields ? <Row>
                        <Col style={{ color: 'red' }}>
                            Please make sure all required fields are filled out.
                        </Col>
                    </Row> : null}
                    {paymentMethod === 'credit' ?
                        <div>
                            <h4>Enter Credit Card</h4>
                            <CreditForm
                                setLastName={setLastName}
                                setFirstName={setFirstName}
                                setEmail={setEmail}
                                setAutopay={setAutopay}
                                submit={submit}
                                setExpirationMonth={setExpirationMonth}
                                setExpirationYear={setExpirationYear}
                                setCardNumber={setCardNumber}
                                setCvv={setCvvOnForm}
                            />
                        </div>
                        : null}
                    {paymentMethod === 'echeck' ? <div>
                        <h4>Enter Checking Account Information</h4>
                        <CheckForm
                            setLastName={setLastName}
                            setFirstName={setFirstName}
                            setEmail={setEmail}
                            setRoutingNumber={setRoutingNumber}
                            submit={submit}
                            setCheckingNumber={setCheckingNumber}
                        />
                    </div> : null}
                    {paymentMethod === 'paypal' ? <div>
                        <h4>Use Paypal Links</h4>
                        <Paypal
                            payAmount={paymentAmount}
                            cid={customerid}
                        />
                    </div> : null}
                </Form>}
                {!showError ? <div><i>* required field</i></div> : null}
        </div>
    )
}

export default PaymentMasterForm;