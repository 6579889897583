import { Col, Row } from "react-bootstrap"
import Switch from "react-bootstrap/esm/Switch"
import { Route } from "react-router"
import { Link } from "react-router-dom"
import Layout from "../components/layout"
import Aeration from "./aeration"
import OrganicFertilizer from "./organicfertilizer"
import PestControl from "./pestcontrol";

const Services = ({match}) => {
    return(

            <Switch>
                <Route path={`${match.path}/organicfertilizer`}>
                    <OrganicFertilizer></OrganicFertilizer>    
                </Route> 
                <Route path={`${match.path}/aeration`}>
                    <Aeration></Aeration>
                </Route>
                <Route path={`${match.path}/pestcontrol`}>
                    <PestControl></PestControl>
                </Route>
                <Route exact path={match.path}>
                    <Layout>
                        <Row>
                            <Col>
                        <h4>We offer these services:</h4>
                        <br></br>
                        <ul>
                            <li> 
                                <Link to='/services/organicfertilizer'>Organic Fertilizer</Link>
                            </li>
                            <li>
                                <Link to='/services/aeration'>Aeration</Link>
                            </li>
                            <li>
                                <Link to='/services/pestcontrol'>Pest Control</Link>
                            </li>
                        </ul>
                        </Col>
                        </Row>
                    </Layout>
                </Route>
            </Switch>
    )
}

export default Services;