import { Accordion, Card, Button } from "react-bootstrap";

const AccordianFaq = () => {
    let cards = require('../json/faq.json').cards;
    console.log(cards);
    return(
        <Accordion defaultActiveKey="1">
      {cards.map((x) =>
          <Card key={x.index}>
          <Card.Header>
            <Accordion.Toggle as={Button} variant="link" style={{color:"gray"}}  eventKey={x.index}>
              {x.question}
            </Accordion.Toggle>
          </Card.Header>
          <Accordion.Collapse eventKey={x.index}>
            <Card.Body>{x.answer}</Card.Body>
          </Accordion.Collapse>
        </Card>
)}
    </Accordion>
    );
}

export default AccordianFaq;