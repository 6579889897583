import { Col, Nav, Row } from "react-bootstrap";
import bbb from '../images/bbb.png'
import pp from '../images/pp.png'
import ccs from '../images/Credit-Card-Visa-And-Master-Card-PNG-File.png'
import nmi from '../images/NMI_Logo_Primary.webp'
import amex from '../images/American Express Square Logo.png'
import letsencrypt from '../images/le-logo-lockonly.svg'

const Footer = () => {
    return(
            <footer  className='clo-footer-style'>
                <Row noGutters>
                    <Col style={{display: 'flex', justifyContent: 'center', marginTop: 20}}>
                            Custom Lawns Of Omaha &copy;{new Date().getFullYear()} Bellevue, NE
                            <br></br><br></br> customlawnsomaha@gmail.com
                    </Col>
                </Row>
                <Row>
                    <Col style={{display: 'flex', justifyContent: 'center', marginTop: 20}}>
                       <img src={bbb} className='clo-footer-image-size'/>
                       <img src={pp} style={{width: '64px', height: '40px', marginTop: '12px'  }}></img>
                       <img src={ccs} className='clo-footer-image-size' ></img> 
                       <img src={amex} className="clo-footer-image-size"/>
                       <img src={nmi} className="clo-footer-image-size"/>
                       <img src={letsencrypt} className='clo-footer-image-size'></img>
                    </Col>
                </Row>
                <Row>
                    <Col>
                    <Nav style={{justifyContent: 'center'}}>
             <Nav.Item>
                     <Nav.Link href='/#/privacy'>
                      <div className='text-secondary' style={{marginTop: 25 }}> Privacy </div>
                     </Nav.Link>
             </Nav.Item>
             <Nav.Item>
                     <Nav.Link href='/#/sitemap'>
                      <div className='text-secondary' style={{marginTop: 25 }}> Sitemap </div>
                     </Nav.Link>
             </Nav.Item>
         </Nav>
                </Col>
                </Row>

            </footer>
    )
}

export default Footer;