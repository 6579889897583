
import './App.css';
import Layout from './components/layout';
import { Col, Row } from 'react-bootstrap';
import TestimonialCarousel from './components/testimonialcarousel';
import grass from './images/grasssized.jpg'
import ImageCarousel from './components/imagecarousel';

function App() {
  return (
    <Layout>
      <Row>
        <Col>
         <ImageCarousel></ImageCarousel>
        </Col>
        </Row>
        <Row>
          <Col>
          <br></br>
          <h4>About Custom Lawns of Omaha</h4>
       <p> Custom Lawns of Omaha is a locally owned and operated lawn care company in the Omaha area. 
        Since 1980 Custom Lawns of Omaha has been providing top-quality service. 
        Our main goal is to make your lawn look the best it can!  
        As a premium service, we feel we offer a one of a kind service that will make your lawn the best on the block.
        We will never upsell you on products and services that you do not need. 
        Our lawn care programs are customized to give you the thickest, greenest lawns possible. 
        <br/>
        </p>
<h4>Our Services</h4>
<p>We Offer</p>
<ul>
<li>Organic Fertilizer Treatments</li>
<li>Insect Control</li>
<li>Aeration</li>
</ul>
<p>
 All our services are backed by a modern infrastructure which includes
 early prepay discounts, electronic payments, emailed invoices and online services. 
 Our knowledgable team is always implementing the best industry techniques to make that lawn pop.
 </p>
<br></br><br></br>
        </Col>
      </Row>
      <Row>
        <Col style={{textAlign:'center'}}>
          <h4>Call Custom Lawns Of Omaha today! 402-291-6800</h4>
        </Col>
      </Row>
      
    </Layout>
  );
}

export default App;
