import { Redirect, useLocation } from "react-router";
import Layout from "../components/layout";
import PaymentMasterForm from "../components/payments/paymentmasterform";

const Payments = () => {
    let location = useLocation();
    let price = new URLSearchParams(location.search).get("balancedue");
    let customerid = new URLSearchParams(location.search).get("cid");
    let inv_comp_id = new URLSearchParams(location.search).get("inv_comp_id");
    let acct_num = new URLSearchParams(location.search).get("acct_num");

    return(
        <Layout>
            <h3>Choose Your Options and Complete the Payment Below</h3>
            <div>
            {price !== null 
                && customerid !== null 
                && inv_comp_id !== null
                && acct_num != null ?  
                <PaymentMasterForm price={price} 
                    customerid={customerid}
                    invCompId={inv_comp_id}
                    acctNumber={acct_num}/> :
                    <Redirect to='/invoice'/>
            }
            </div>
        </Layout>
    )
}

export default Payments;