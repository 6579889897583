import { Col, Row } from "react-bootstrap"
import AccordianFaq from "../components/accordianfaq"
import Layout from "../components/layout"

const FAQ = () => {
    return(
        <Layout>
            <Row>
                <Col>
                    <AccordianFaq></AccordianFaq>
                </Col>
            </Row>
        </Layout>
    )
}

export default FAQ;