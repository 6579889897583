import { useState } from "react"
import { Form, InputGroup, Button } from "react-bootstrap"

const InvoiceForm = () => {
    const [accountNumber, setAccountNumber] = useState('');
    const [houseNumber, setHouseNumber] = useState('');

    const handleChange = (e) => {
        if(e.target.name === 'acct_num'){
            setAccountNumber(e.target.value);
        }
        else if(e.target.name === 'address'){
            setHouseNumber(e.target.value);
        }
    }

    const getInvoice = (e) =>{
        var searchParams = new URLSearchParams();
        searchParams.set('acct_num', accountNumber);
        searchParams.set('address', houseNumber);
        searchParams.set('select', 'Get Invoice');
        searchParams.set('inv_comp_id', '42009020813');

        window.location = 'https://customlawnsofomaha.com/invoice.php?'+searchParams.toString();
    }

    return(
        <Form action='https://www.customlawnsofomaha.com/invoice.php' method='POST'>
            <Form.Control type='hidden' name='select' value="Get Invoice"/>
            <Form.Control type='hidden' name='inv_comp_id' value='42009020813' />
            <Form.Group>
        <Form.Label>Account Number</Form.Label>
        <InputGroup>
          <Form.Control  required type='text' name='acct_num' placeholder='a1234' onChange={handleChange}/>
          <Form.Control.Feedback type='invalid' name='acct_num'>
              Please include your account number
          </Form.Control.Feedback>
        </InputGroup>
      </Form.Group>
      <Form.Group>
      <Form.Label>House Number</Form.Label>
      <InputGroup>
          <Form.Control  required type='text' name='address' placeholder='1234' onChange={handleChange}/>
          <Form.Control.Feedback type='invalid' name='address'>
              Please include your house number
          </Form.Control.Feedback>
        </InputGroup>
      </Form.Group>
      <Button type='submit' variant='outline-success'>View Invoice</Button>
        </Form>
    )
}

export default InvoiceForm;