import { Col, Row } from "react-bootstrap"
import InvoiceForm from "../components/invoiceform"
import Layout from "../components/layout"
import Lily from '../images/coollilyopt.jpg'

const Invoice = () =>{
    return(
        <Layout>
            <Row>
            <Col>
                <h4>Use your account number and house number to pay your invoice here</h4>
            </Col>
            </Row>
            <Row>
                <Col>
                    <InvoiceForm></InvoiceForm>
                </Col>
                <Col>
                    <img src={Lily} style={{maxHeight:'250px'}}/>
                </Col>
            </Row>
        </Layout>
    )
}

export default Invoice;