import { Row, Col } from "react-bootstrap";
import Layout from "../components/layout";
import house from "../images/brickhouse2.jpg"

const PestControl = () =>{
    return(
        <Layout>
            <Row>
                <Col>
                <h4> Got Some Annoying Pests!</h4>
                Custom Lawns is licensed to spray for insects. We will come out and spray the exterior perimeter
                of your house at reasonable and competitive prices. This application is reccomended to be done 4 times a year.  
                <br></br><br></br>
                </Col>
                <Col>
                <img src={house} style={{ maxHeight: '300px'}}/>
                </Col>
            </Row>
        </Layout>
    )
}

export default PestControl;