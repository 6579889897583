import {Row, Col, Form, Button} from "react-bootstrap";

const CheckForm = ({setFirstName, setLastName, setRoutingNumber, setCheckingNumber, submit, setEmail}) => {
    return(
        <div>
            <Row className="mb-6">
                <Form.Group as={Col}>
                <Form.Label>First Name:* </Form.Label>
                <Form.Control onChange={(e)=>setFirstName(e.target.value)}/>
                </Form.Group>
                <Form.Group as={Col}>
                <Form.Label>Last Name:* </Form.Label>
                <Form.Control onChange={(e)=>setLastName(e.target.value)}/>
                </Form.Group>
            </Row>
            <Row className="mb-6">
                <Form.Group as={Col}>
                    <Form.Label>9-Digit ABA Routing Number:* </Form.Label>
                    <Form.Control onChange={(e) => setRoutingNumber(e.target.value)}/>
                </Form.Group>
                <Form.Group as={Col}>
                    <Form.Label>Checking Account Number:*</Form.Label>
                    <Form.Control onChange={(e) => setCheckingNumber(e.target.value)}/>
                </Form.Group>
            </Row>
            <Row>
                <Form.Group as={Col}>
                    <Form.Label>Email Address: </Form.Label>
                    <Form.Control type='email' onChange={(e)=>setEmail(e.target.value)}/>
                </Form.Group>
            </Row>
            <Row>
                <Col>
                    <Button variant='outline-success' type="button" onClick={() => submit()}>Send Payment</Button>
                </Col>
            </Row>
            </div>
    )
}

export default CheckForm;