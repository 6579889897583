import { useState } from 'react';
import {Modal, Button, Form, InputGroup } from 'react-bootstrap'
import axios from 'axios';

const ContactModal = ({open, toggle}) =>{
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [address, setAddress] = useState('');
  const [validated, setValidated] = useState(false);

  const handleChange = (e) => {
    if(e.target.name === 'name'){
      setName(e.target.value);
    }
    else if(e.target.name === 'email'){
      setEmail(e.target.value);
    }
    else if(e.target.name === 'address'){
      setAddress(e.target.value);
    }
  }

  const send = (e) =>{
          setValidated(true);
          axios.post('/api/sendestimateemail.php', {
              name: name,
              email: email,
              address: address
            });
  }

  return(
    <Modal show={open}>
        <Modal.Dialog>
  <Modal.Header closeButton onHide={() => toggle(false)}>
    <Modal.Title>Get an Estimate For Your Property</Modal.Title>
  </Modal.Header>

  <Modal.Body>
    {validated  ? <div> Thank you for contacting us, we will send you an estimate within 48 hours</div> :
    <Form  onSubmit={send}>
      <Form.Group>
        <Form.Label>Name</Form.Label>
        <InputGroup>
          <Form.Control  required type='text' name='name' placeholder='John Smith' onChange={handleChange}/>
          <Form.Control.Feedback type='invalid'>
              Please include your name 
          </Form.Control.Feedback>
        </InputGroup>
      </Form.Group>
      <Form.Group>
      <Form.Label>Email</Form.Label>
      <InputGroup>
          <Form.Control  required type='email' name='email' placeholder='jsmith@me.com' onChange={handleChange}/>
          <Form.Control.Feedback type='invalid'>
              Please include your email address
          </Form.Control.Feedback>
        </InputGroup>
      </Form.Group>
      <Form.Group>
      <Form.Label>Property Address</Form.Label>
      <InputGroup>
          <Form.Control  required type='text' name='address' placeholder='123 Rd, Bellvue, NE 12345' onChange={handleChange}/>
          <Form.Control.Feedback type='invalid'>
              Please include your Address
          </Form.Control.Feedback>
        </InputGroup>
      </Form.Group>
      <Button type='submit' variant='outline-success'>Get Estimate</Button>
    </Form>
}
  </Modal.Body>

  <Modal.Footer>
    <Button variant="secondary" onClick={() => toggle(false)}>Close</Button>
  </Modal.Footer>
</Modal.Dialog>
</Modal>
    )
}

export default ContactModal;